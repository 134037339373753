import * as React from "react";

import { FormSpec, localized } from "../../../form-specs";
import { MenuWidgetSpec, Widget } from "../../";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { Label } from "./Label";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { WidgetGroup } from "../../widget.enum";
import { getI18nLocaleObject } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";

export interface LocalizedLabel extends LocalizedContentBase {
    label: string;
}

export interface WidgetOptions {
    localized: LocalizedLabel[];
}

const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "label-widget-options",
    name: getI18nLocaleObject(namespaceList.widgetLabel, "labelWidgetOptions"),
    pluralName: getI18nLocaleObject(namespaceList.widgetLabel, "labelWidgetOptions"),
    properties: [
        localized({
            variable: "localized",
            tabContent: [
                {
                    variable: "label",
                    label: getI18nLocaleObject(namespaceList.widgetLabel, "label"),
                    type: "text",
                },
            ],
        }),
    ],
};

export const labelWidget: MenuWidgetSpec<WidgetOptions> = {
    id: "label",
    type: "menu",
    widgetGroup: WidgetGroup.OTHER,
    name: getI18nLocaleObject(namespaceList.widgetLabel, "labelWidget"),
    description: getI18nLocaleObject(namespaceList.widgetLabel, "labelWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        localized: [],
    }),
    container: false,
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const { currentLocale, site } = context;
        const localizedLabel = getLocalizedContent({ site, currentLocale, localizedContent: widget.options.localized });
        return <Label key={widget._id} label={localizedLabel?.label || ""} />;
    },
};
