import * as React from "react";

import { DropdownItem } from "reactstrap";

interface LabelProps {
    label: string;
}

interface LabelState {}

export class Label extends React.PureComponent<LabelProps, LabelState> {
    public render(): JSX.Element | null {
        const { label } = this.props;
        return <DropdownItem header>{label}</DropdownItem>;
    }
}
